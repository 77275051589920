<template>
  <div>
    <div class="attribute">
      <p>商品sku:</p>
      <div>
        <el-button @click="checkModelDetail()" type="primary">新增sku </el-button>
      </div>
    </div>

    <el-table
      :data="model_list"
      style="width: 100%"
      :cell-style="$common.tableStyle"
      :header-cell-style="$common.tableStyle"
      border
      default-expand-all
      fit
      row-key="id"
    >
      <el-table-column label="sku" prop="name">
        <template #default="scope">
          <span style="font-weight: bold">{{ scope.row.sku }}</span>
        </template>
      </el-table-column>
      <el-table-column label="口味" prop="name">
        <template #default="scope">
          <span style="font-weight: bold">{{ scope.row.flavor }}</span>
        </template>
      </el-table-column>

      <el-table-column label="pc图片">
        <template #default="scope">
          <el-image
            fit="contain"
            style="width: 100px; height: 100px"
            :src="scope.row.pc_flavor_image_url + $common.K_QINIU_IMAGE_SMALL_END_100_PX"
            :preview-src-list="[scope.row.pc_flavor_image_url]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="wap图片">
        <template #default="scope">
          <el-image
            fit="contain"
            style="width: 100px; height: 100px"
            :src="scope.row.wap_flavor_image_url + $common.K_QINIU_IMAGE_SMALL_END_100_PX"
            :preview-src-list="[scope.row.wap_flavor_image_url]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="pc小盒图片">
        <template #default="scope">
          <el-image
            fit="contain"
            style="width: 100px; height: 100px"
            :src="scope.row.pc_small_box_image_url + $common.K_QINIU_IMAGE_SMALL_END_100_PX"
            :preview-src-list="[scope.row.pc_small_box_image_url]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="pc大盒图片">
        <template #default="scope">
          <el-image
            fit="contain"
            style="width: 100px; height: 100px"
            :src="scope.row.pc_mid_box_image_url + $common.K_QINIU_IMAGE_SMALL_END_100_PX"
            :preview-src-list="[scope.row.pc_mid_box_image_url]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="权重" width="80" align="center">
        <template slot-scope="scope">
          <p style="font-weight: bold">{{ scope.row.number }}</p>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="120" align="center">
        <template slot-scope="scope">
          <el-tag
            style="font-weight: bold"
            :type="$common.CommonStatusList[scope.row.status].tag"
            effect="dark"
            >{{ $common.CommonStatusList[scope.row.status].name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" align="center">
        <template slot-scope="scope">
          <el-button
            style="color: #03aef9"
            @click="checkModelDetail(scope.row)"
            type="text"
            >编辑
          </el-button>
          <el-button
            style="color: #f03c69"
            @click="removeModelConfirm(scope.row)"
            type="text"
            >移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  emits: ["switchSkuHandle"],
  // 产品详情 - 基本信息编辑页面
  name: "ProductSkuListModule",
  props: {
    productId: {
      type: String,
      default: "",
    },
    operateType: {
      type: String,
      default: "",
    },
    switchSkuHandle: {
      type: Function,
      default: () => {
        return Function;
      },
    },
  },
  data() {
    return {
      loading: false,
      product_operate_type: this.operateType,
      product_model_id: this.productId,
      year_list: [],
      model_list: [],
      id: "",
    };
  },

  mounted() {
    this.id = this.$route.query.id;

    if (this.id) {
      // 编辑
      this.getProductSkuList();
    }

    // console.log(this.switchSkuHandle())
  },

  methods: {
    getProductSkuList() {
      let _me = this;
      let params = {
        product_id: _me.id,
        page_size: -1,
        page_number: 1,
        sort_fields: "-number",
      };
      _me.$common
        .httpPost("/api/product/admin/sku/find/list", params)
        .then(async (res) => {
          if (res.code === "1000000") {
            _me.model_list = res.data.array === undefined ? [] : res.data.array;
          }
        })
        .catch((err) => {
          _me.$message({
            message: err || "数据错误",
            type: "error",
          });
        });
    },

    checkModelDetail(data) {
      let _me = this;
      if (data) {
        _me.switchSkuHandle(data.id);
        // emit("switchSkuHandle", data.id);
      } else {
        _me.switchSkuHandle();
        // emit("switchSkuHandle");
      }
    },

    removeModelConfirm(item) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _me.removeModelDetail(item);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("取消删除");
        });
    },

    removeModelDetail(item) {
      let _me = this;
      _me.loading = true;
      let params = {
        id: item.id,
      };
      _me.$common
        .httpPost("/api/product/admin/sku/delete", params)
        .then((res) => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.$message.success("删除成功");
            _me.getProductSkuList();
          } else {
            _me.$message({
              message: res.message || "数据错误",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "数据错误",
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.attribute {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
</style>
