<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header class="back" title="返回" @back="goBack" content="详情分类信息"></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveMountModel">保存</el-button>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="mount-form"
          :model="category_model"
          :rules="rules"
          ref="category_model"
          label-width="120px"
        >
          <el-form-item label="分类名称:" prop="name">
            <el-input v-model="category_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="分类介绍:">
            <el-input v-model="category_model.info" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select v-model="category_model.status" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序:">
            <el-input-number v-model="category_model.number" :min="0" label="排序"></el-input-number>
          </el-form-item>
          <el-form-item label="PC是否可用:">
            <el-switch v-model="category_model.vue_pc_status" active-text="是" inactive-text="否"></el-switch>
          </el-form-item>
          <el-form-item v-if="category_model.vue_pc_status" label="PC组件:" prop="vue_pc_name">
            <el-input v-model="category_model.vue_pc_name" clearable></el-input>
          </el-form-item>
          <el-form-item label="Wap是否可用:">
            <el-switch v-model="category_model.vue_wap_status" active-text="是" inactive-text="否"></el-switch>
          </el-form-item>
          <el-form-item v-if="category_model.vue_wap_status" label="Wap组件:" prop="vue_wap_name">
            <el-input v-model="category_model.vue_wap_name" clearable></el-input>
          </el-form-item>
          <CopyPaste
            :button_status="this.category_id !== undefined && this.category_id.length > 0"
            @copyInfo="copyInfo(category_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaste"
          ></CopyPaste>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CopyPaste from "../../../../components/CopyPaste.vue";

export default {
  name: "ProductDetailCategoryDetailView",
  components: {
    CopyPaste
  },
  data() {
    return {
      loading: false,
      category_id: "",
      category_model: {
        name: "",
        info: "",
        vue_pc_status: true,
        vue_pc_name: "",
        vue_wap_status: true,
        vue_wap_name: "",
        number: 0,
        status: 2
      },
      rules: {
        name: [
          { required: true, message: "请输入组件名称", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.category_id = this.$route.query.id;
    if (this.category_id !== undefined && this.category_id.length > 0) {
      this.getCategoryModel();
    }
  },
  methods: {
    getCategoryModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.category_id
      };
      this.$common
        .httpPost("/api/product/admin/detail/category/find/id", params)
        .then(res => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.category_model = res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    async saveMountModel() {
      if (!(await this.submitValidate())) return;
      if (this.category_id !== undefined && this.category_id.length > 0) {
        this.updateMountModel();
      } else {
        this.newMountModel();
      }
    },
    async submitValidate() {
      try {
        await this.$refs["category_model"].validate();
        // 验证成功
        return true;
      } catch (err) {
        this.$message({
          message: "请检查表单",
          type: "error"
        });
        return false;
      }
    },
    newMountModel() {
      let _me = this;
      let params = {
        name: this.category_model.name,
        info: this.category_model.info,
        vue_pc_status: this.category_model.vue_pc_status,
        vue_pc_name: this.category_model.vue_pc_name,
        vue_wap_status: this.category_model.vue_wap_status,
        vue_wap_name: this.category_model.vue_wap_name,
        number: this.category_model.number,
        status: this.category_model.status
      };
      this.loading = true;
      this.$common
        .httpPost("/api/product/admin/detail/category/add", params)
        .then(res => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    updateMountModel() {
      let _me = this;
      let params = {
        id: this.category_id,
        name: this.category_model.name,
        info: this.category_model.info,
        vue_pc_status: this.category_model.vue_pc_status,
        vue_pc_name: this.category_model.vue_pc_name,
        vue_wap_status: this.category_model.vue_wap_status,
        vue_wap_name: this.category_model.vue_wap_name,
        number: this.category_model.number,
        status: this.category_model.status
      };
      this.loading = true;
      this.$common
        .httpPost("/api/product/admin/detail/category/update", params)
        .then(res => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.$message.success("内容修改成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.category_model = model;
      this.category_model.id = this.category_id;
    }
  }
};
</script>

<style scoped>
.edit-row-dt {
  padding: 5px 0 0px 0;
  margin: 0;
  width: 100%;
}

.mount-form {
  margin: 20px 10px 10px 0;
  text-align: left;
  min-width: 600px;
  max-width: 600px;
}
</style>
