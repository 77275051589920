<template>
  <div v-loading="loading" class="main-frame">
    <el-page-header content="" @back="goBack" style="margin: 5px 0" />
    <el-form
      v-loading="loading"
      class="type-form"
      :model="product_model"
      label-width="140px"
      :rules="rules"
      ref="modelForm"
    >
      <el-form-item label="sku:" prop="sku">
        <el-input
          v-model.trim="product_model.sku"
          :disabled="product_model.id ? true : false"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="口味:" prop="flavor">
        <el-input v-model="product_model.flavor" clearable></el-input>
      </el-form-item>
      <el-form-item label="颜色:" prop="color">
        <el-color-picker v-model="product_model.color"></el-color-picker>
      </el-form-item>
      <el-form-item label="吸量:" prop="puffs">
        <el-input v-model="product_model.puffs" clearable></el-input>
      </el-form-item>
      <el-form-item label="尼古丁:" prop="nicotine">
        <el-input v-model="product_model.nicotine" clearable></el-input>
      </el-form-item>
      <el-form-item label="电量:" prop="mah">
        <el-input v-model="product_model.mah" clearable></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="product_model.status"
          placeholder="请选择"
          loading-text="loading"
        >
          <el-option
            v-for="item in $common.StatusSelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="权重">
        <el-input-number
          v-model="product_model.number"
          :min="0"
          label="权重"
        ></el-input-number>
      </el-form-item>

      <el-form-item label="pc图标:">
        <el-input v-model="product_model.pc_image_url" clearable></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              fit="contain"
              style="width: 240px; height: 240px; margin-top: 10px"
              :src="
                product_model.pc_image_url + $common.K_QINIU_IMAGE_SMALL_END_200
              "
              :preview-src-list="[product_model.pc_image_url]"
              lazy
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl>
              <dt class="edit-row-dt">
                <el-button
                  size="mini"
                  type="warning"
                  @click="getImageByWeb('pc_image_url')"
                >
                  资源选择
                </el-button>
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="wap图标:">
        <el-input v-model="product_model.wap_image_url" clearable></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              fit="contain"
              style="width: 240px; height: 240px; margin-top: 10px"
              :src="
                product_model.wap_image_url +
                $common.K_QINIU_IMAGE_SMALL_END_200
              "
              :preview-src-list="[product_model.wap_image_url]"
              lazy
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl>
              <dt class="edit-row-dt">
                <el-button
                  size="mini"
                  type="warning"
                  @click="getImageByWeb('wap_image_url')"
                >
                  资源选择
                </el-button>
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="PC口味图片:">
        <el-input
          v-model="product_model.pc_flavor_image_url"
          clearable
        ></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              fit="contain"
              style="width: 240px; height: 240px; margin-top: 10px"
              :src="
                product_model.pc_flavor_image_url +
                $common.K_QINIU_IMAGE_SMALL_END_200
              "
              :preview-src-list="[product_model.pc_flavor_image_url]"
              lazy
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl>
              <dt class="edit-row-dt">
                <el-button
                  size="mini"
                  type="warning"
                  @click="getImageByWeb('pc_flavor_image_url')"
                >
                  资源选择
                </el-button>
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="WAP口味图片:">
        <el-input
          v-model="product_model.wap_flavor_image_url"
          clearable
        ></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              fit="contain"
              style="width: 240px; height: 240px; margin-top: 10px"
              :src="
                product_model.wap_flavor_image_url +
                $common.K_QINIU_IMAGE_SMALL_END_200
              "
              :preview-src-list="[product_model.wap_flavor_image_url]"
              lazy
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl>
              <dt class="edit-row-dt">
                <el-button
                  size="mini"
                  type="warning"
                  @click="getImageByWeb('wap_flavor_image_url')"
                >
                  资源选择
                </el-button>
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="PC小盒图片:">
        <el-input
          v-model="product_model.pc_small_box_image_url"
          clearable
        ></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              fit="contain"
              style="width: 240px; height: 240px; margin-top: 10px"
              :src="
                product_model.pc_small_box_image_url +
                $common.K_QINIU_IMAGE_SMALL_END_200
              "
              :preview-src-list="[product_model.pc_small_box_image_url]"
              lazy
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl>
              <dt class="edit-row-dt">
                <el-button
                  size="mini"
                  type="warning"
                  @click="getImageByWeb('pc_small_box_image_url')"
                >
                  资源选择
                </el-button>
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="WAP小盒图片:">
        <el-input
          v-model="product_model.wap_small_box_image_url"
          clearable
        ></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              fit="contain"
              style="width: 240px; height: 240px; margin-top: 10px"
              :src="
                product_model.wap_small_box_image_url +
                $common.K_QINIU_IMAGE_SMALL_END_200
              "
              :preview-src-list="[product_model.wap_small_box_image_url]"
              lazy
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl>
              <dt class="edit-row-dt">
                <el-button
                  size="mini"
                  type="warning"
                  @click="getImageByWeb('wap_small_box_image_url')"
                >
                  资源选择
                </el-button>
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="PC大盒图片:">
        <el-input
          v-model="product_model.pc_mid_box_image_url"
          clearable
        ></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              fit="contain"
              style="width: 240px; height: 240px; margin-top: 10px"
              :src="
                product_model.pc_mid_box_image_url +
                $common.K_QINIU_IMAGE_SMALL_END_200
              "
              :preview-src-list="[product_model.pc_mid_box_image_url]"
              lazy
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl>
              <dt class="edit-row-dt">
                <el-button
                  size="mini"
                  type="warning"
                  @click="getImageByWeb('pc_mid_box_image_url')"
                >
                  资源选择
                </el-button>
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="WAP大盒图片:">
        <el-input
          v-model="product_model.wap_mid_box_image_url"
          clearable
        ></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              fit="contain"
              style="width: 240px; height: 240px; margin-top: 10px"
              :src="
                product_model.wap_mid_box_image_url +
                $common.K_QINIU_IMAGE_SMALL_END_200
              "
              :preview-src-list="[product_model.wap_mid_box_image_url]"
              lazy
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl>
              <dt class="edit-row-dt">
                <el-button
                  size="mini"
                  type="warning"
                  @click="getImageByWeb('wap_mid_box_image_url')"
                >
                  资源选择
                </el-button>
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <div style="height: 4px"></div>
      </el-form-item>
      <el-form-item>
        <el-button
          style="width: 300px; height: 50px"
          type="danger"
          @click="saveModelDetail"
          class="fixed-button"
          >保存</el-button
        >
      </el-form-item>

      <el-form-item>
        <div style="height: 40px"></div>
      </el-form-item>
    </el-form>
    <!-- </el-tab-pane> -->

    <!-- </el-tabs> -->

    <FileChooseDrawerModule ref="productSkuDetailFileChooseDrawerModule" />
  </div>
</template>

<script>
import FileChooseDrawerModule from "../../../file/module/FileChooseDrawerModule.vue";

export default {
  name: "ProductDetailSkuModule",
  emits: ["switchSkuHandle"],
  components: {
    FileChooseDrawerModule,
  },
  props: {
    productId: {
      type: String,
      default: "",
    },
    operateType: {
      type: String,
      default: "",
    },
    skuId: {
      type: String,
      default: "",
    },
    switchSkuHandle: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      product_operate_type: this.operateType,
      product_model_id: this.productId,
      sku_id: this.skuId,
      product_model: {
        id: "",
        sku: "",
        flavor: "",
        puffs: "",
        nicotine: "",
        mah: "",
        color: "",
        status: 2,
        number: 1,
        product_id: "",
        pc_image_url: "",
        wap_image_url: "",
        pc_flavor_image_url: "",
        wap_flavor_image_url: "",
        pc_small_box_image_url: "",
        wap_small_box_image_url: "",
        pc_mid_box_image_url: "",
        wap_mid_box_image_url: "",
      },

      rules: {
        sku: [
          {
            required: true,
            message: "请填写sku",
            trigger: "blur",
          },
        ],
      },
    };
  },

  mounted() {
    let _me = this;
    if (_me.product_operate_type.length > 0) {
      if (_me.sku_id.length > 0) {
        //   存在id就是进入修改，先查询当前产品详情
        _me.sku_id = _me.sku_id;
        _me.getModelDetail();
      }
    } else {
      _me.$message.error("数据错误");
    }
  },

  methods: {
    getModelDetail() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.sku_id,
      };
      _me.$common
        .httpPost("/api/product/admin/sku/find/id", params)
        .then(async (res) => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.product_model = res.data ? res.data : [];
          } else {
            _me.$message({
              message: res.message || "数据错误",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "数据错误",
            type: "error",
          });
        });
    },

    saveModelDetail() {
      let _me = this;
      this.$refs["modelForm"].validate((valid) => {
        if (valid) {
          if (_me.sku_id && _me.sku_id.length > 0) {
            _me.updateProductSku();
          } else {
            _me.addNewProductSku();
          }
        } else {
          _me.$message({
            message: "请检查表单",
            type: "error",
          });
        }
      });
    },
    addNewProductSku() {
      let _me = this;
      let params = {
        product_id: _me.product_model_id,
        sku: _me.product_model.sku,
        flavor: _me.product_model.flavor,
        puffs: _me.product_model.puffs,
        nicotine: _me.product_model.nicotine,
        mah: _me.product_model.mah,
        pc_image_url: _me.product_model.pc_image_url,
        wap_image_url: _me.product_model.wap_image_url,
        pc_flavor_image_url: _me.product_model.pc_flavor_image_url,
        wap_flavor_image_url: _me.product_model.wap_flavor_image_url,
        pc_small_box_image_url: _me.product_model.pc_small_box_image_url,
        wap_small_box_image_url: _me.product_model.wap_small_box_image_url,
        pc_mid_box_image_url: _me.product_model.pc_mid_box_image_url,
        wap_mid_box_image_url: _me.product_model.wap_mid_box_image_url,
        number: _me.product_model.number,
        status: _me.product_model.status,
        color: _me.product_model.color,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/product/admin/sku/add", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("sku添加成功");
            this.switchSkuHandle();
            // emit("switchSkuHandle");
          } else {
            _me.$message({
              message: res.message || "数据错误",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "数据错误",
            type: "error",
          });
        });
    },
    updateProductSku() {
      let _me = this;
      let params = {
        id: _me.product_model.id,
        sku: _me.product_model.sku,
        flavor: _me.product_model.flavor,
        puffs: _me.product_model.puffs,
        nicotine: _me.product_model.nicotine,
        mah: _me.product_model.mah,
        pc_image_url: _me.product_model.pc_image_url,
        wap_image_url: _me.product_model.wap_image_url,
        pc_flavor_image_url: _me.product_model.pc_flavor_image_url,
        wap_flavor_image_url: _me.product_model.wap_flavor_image_url,
        pc_small_box_image_url: _me.product_model.pc_small_box_image_url,
        wap_small_box_image_url: _me.product_model.wap_small_box_image_url,
        pc_mid_box_image_url: _me.product_model.pc_mid_box_image_url,
        wap_mid_box_image_url: _me.product_model.wap_mid_box_image_url,
        number: _me.product_model.number,
        status: _me.product_model.status,
        color: _me.product_model.color,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/product/admin/sku/update", params)
        .then((res) => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.$message.success("sku修改成功");
            // this.switchSkuHandle();
            _me.getModelDetail();
          } else {
            _me.$message({
              message: res.message || "数据错误",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "数据错误",
            type: "error",
          });
        });
    },
    getImageByWeb(imageType) {
      let _me = this;
      _me.$refs.productSkuDetailFileChooseDrawerModule.showChooseDrawer(
        function (chooseFileList) {
          // console.log("接收到选择的文件:", chooseFileList);
          if (chooseFileList.length > 0) {
            // _me.product_model.imageType = chooseFileList[0].file_url;
            switch (imageType) {
              case "pc_image_url":
                _me.product_model.pc_image_url = chooseFileList[0].file_url;
                break;
              case "wap_image_url":
                _me.product_model.wap_image_url = chooseFileList[0].file_url;
                break;
              case "pc_flavor_image_url":
                _me.product_model.pc_flavor_image_url =
                  chooseFileList[0].file_url;
                break;
              case "wap_flavor_image_url":
                _me.product_model.wap_flavor_image_url =
                  chooseFileList[0].file_url;
                break;
              case "pc_small_box_image_url":
                _me.product_model.pc_small_box_image_url =
                  chooseFileList[0].file_url;
                break;
              case "wap_small_box_image_url":
                _me.product_model.wap_small_box_image_url =
                  chooseFileList[0].file_url;
                break;
              case "pc_mid_box_image_url":
                _me.product_model.pc_mid_box_image_url =
                  chooseFileList[0].file_url;
                break;
              case "wap_mid_box_image_url":
                _me.product_model.wap_mid_box_image_url =
                  chooseFileList[0].file_url;
                break;
              default:
                break;
            }
          }
        }
      );
    },

    goBack() {
      this.switchSkuHandle();
      //   emit("switchSkuHandle");
    },
  },
};
</script>
<style scoped>
.edit-form {
  margin: 20px 10px 10px 0;
  text-align: left;
  min-width: 720px;
  max-width: 720px;
}

.fixed-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
}
</style>
