<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left;" :span="12">
              <el-page-header
                style="color: #333333; margin-top: 10px;"
                title="返回"
                @back="goBack"
                content="组件详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right;" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveMountModel">保存</el-button>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="mount-form"
          :model="mount_model"
          :rules="rules"
          ref="mount_model"
          label-width="120px"
        >
          <el-form-item label="组件名称:" prop="name">
            <el-input v-model="mount_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="分类:" prop="category_id">
            <el-select v-model="mount_model.category_id" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in detail_category_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="介绍:">
            <el-input v-model="mount_model.info" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select v-model="mount_model.status" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序:">
            <el-input-number v-model="mount_model.number" :min="0" label="排序"></el-input-number>
          </el-form-item>
          <el-form-item label="PC是否可用:">
            <el-switch v-model="mount_model.vue_pc_status" active-text="是" inactive-text="否"></el-switch>
          </el-form-item>
          <el-form-item v-if="mount_model.vue_pc_status" label="PC组件:" prop="vue_pc_name">
            <el-input v-model="mount_model.vue_pc_name" clearable></el-input>
          </el-form-item>
          <el-form-item label="Wap是否可用:">
            <el-switch v-model="mount_model.vue_wap_status" active-text="是" inactive-text="否"></el-switch>
          </el-form-item>
          <el-form-item v-if="mount_model.vue_wap_status" label="Wap组件:" prop="vue_wap_name">
            <el-input v-model="mount_model.vue_wap_name" clearable></el-input>
          </el-form-item>
          <el-form-item label="模型Json:">
            <el-input
              v-model="mount_model.json_data"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8}"
            ></el-input>
          </el-form-item>
          <el-form-item label="示例json:">
            <el-input
              v-model="mount_model.sample_json"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8}"
            ></el-input>
          </el-form-item>
          <el-form-item style="text-align: left;" label="示例图片:">
            <el-input v-model="mount_model.sample_image" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  fit="contain"
                  style="width: 100px; height: 100px; margin-top: 10px;"
                  :src="mount_model.sample_image + $common.K_QINIU_IMAGE_SMALL_END_200"
                  :preview-src-list="[mount_model.sample_image]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('sample_image')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
    <FileChooseDrawerModule ref="productDetailModuleDetailViewFileChooseDrawerModule" />
  </div>
</template>

<script>
import FileChooseDrawerModule from "../../../file/module/FileChooseDrawerModule";

export default {
  name: "ProductDetailModuleDetailView",
  components: {
    FileChooseDrawerModule
  },
  data() {
    return {
      loading: false,
      mount_id: "",
      mount_model: {
        category_id: "",
        name: "",
        info: "",
        vue_pc_status: true,
        vue_pc_name: "",
        vue_wap_status: true,
        vue_wap_name: "",
        json_data: "",
        sample_json: "",
        sample_image: "",
        number: 0,
        status: 2
      },
      rules: {
        name: [
          { required: true, message: "请输入组件名称", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" }
        ],
        category_id: [
          { required: true, message: "请选择组件分类", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" }
        ]
      },
      detail_category_list: []
    };
  },
  created() {
    this.mount_id = this.$route.query.id;
    this.getDetailCategoryList();
    if (this.mount_id !== undefined && this.mount_id.length > 0) {
      this.getMountModel();
    }
  },
  methods: {
    getDetailCategoryList() {
      let _me = this;
      let params = {
        status: 0,
        page_size: 20,
        page_number: 1,
        sort_fields: ""
      };
      this.loading = true;
      this.$common
        .httpPost("/api/product/admin/detail/category/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.detail_category_list =
              res.data.array === undefined ? [] : res.data.array;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getMountModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.mount_id
      };
      this.$common
        .httpPost("/api/product/admin/detail/module/find/id", params)
        .then(res => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.mount_model = res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    saveMountModel() {
      if (this.mount_id !== undefined && this.mount_id.length > 0) {
        this.updateMountModel();
      } else {
        this.newMountModel();
      }
    },
    newMountModel() {
      let _me = this;
      let params = {
        category_id: this.mount_model.category_id,
        name: this.mount_model.name,
        info: this.mount_model.info,
        vue_pc_status: this.mount_model.vue_pc_status,
        vue_pc_name: this.mount_model.vue_pc_name,
        vue_wap_status: this.mount_model.vue_wap_status,
        vue_wap_name: this.mount_model.vue_wap_name,
        json_data: this.mount_model.json_data,
        sample_json: this.mount_model.sample_json,
        sample_image: this.mount_model.sample_image,
        number: this.mount_model.number,
        status: this.mount_model.status
      };
      this.loading = true;
      this.$common
        .httpPost("/api/product/admin/detail/module/add", params)
        .then(res => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    updateMountModel() {
      let _me = this;
      let params = {
        id: this.mount_id,
        category_id: this.mount_model.category_id,
        name: this.mount_model.name,
        info: this.mount_model.info,
        vue_pc_status: this.mount_model.vue_pc_status,
        vue_pc_name: this.mount_model.vue_pc_name,
        vue_wap_status: this.mount_model.vue_wap_status,
        vue_wap_name: this.mount_model.vue_wap_name,
        json_data: this.mount_model.json_data,
        sample_json: this.mount_model.sample_json,
        sample_image: this.mount_model.sample_image,
        number: this.mount_model.number,
        status: this.mount_model.status
      };
      this.loading = true;
      this.$common
        .httpPost("/api/product/admin/detail/module/update", params)
        .then(res => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.$message.success("内容修改成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getImageByWeb(imageType) {
      let _me = this;
      this.$refs.productDetailModuleDetailViewFileChooseDrawerModule.showChooseDrawer(
        function(chooseFileList) {
          console.log("接收到选择的文件:", chooseFileList);
          if (chooseFileList.length > 0) {
            switch (imageType) {
              case "sample_image":
                _me.mount_model.sample_image = chooseFileList[0].file_url;
                break;
              default:
                break;
            }
          }
        }
      );
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    }
  }
};
</script>

<style scoped>
.banner-head {
  /*background-color: white;*/
  background-color: #f1f1f1;
  height: 60px;
}

.head-info {
  margin-top: 10px;
  color: #ffffff;
}

.edit-row-dt {
  padding: 5px 0 0px 0;
  margin: 0;
  width: 100%;
}

.mount-form {
  margin: 20px 10px 10px 0;
  text-align: left;
  min-width: 600px;
  max-width: 600px;
}
</style>