import { render, staticRenderFns } from "./ProductListView.vue?vue&type=template&id=23b868da&scoped=true"
import script from "./ProductListView.vue?vue&type=script&lang=js"
export * from "./ProductListView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b868da",
  null
  
)

export default component.exports