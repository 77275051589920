<template>
  <div>
    <el-form
      v-loading="loading"
      class="product-info-form"
      :model="product_model"
      :rules="rules"
      ref="mount_model"
      label-width="140px"
    >
      <CopyPaste
        :button_status="
          this.productId !== undefined && this.productId.length > 0
        "
        @copyInfo="copyInfo(product_model)"
        @pasteInfo="pasteInfo"
        ref="copyPaste"
      ></CopyPaste>
      <el-form-item label="产品名称:" prop="name">
        <el-input v-model="product_model.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="状态:">
        <el-select
          v-model="product_model.status"
          placeholder="请选择"
          loading-text="加载中..."
        >
          <el-option
            v-for="item in $common.StatusSelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="权重:">
        <el-input-number
          v-model="product_model.number"
          :min="0"
          label="排序"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="产品别名:">
        <el-input v-model="product_model.old_alias" clearable></el-input>
      </el-form-item>
      <el-form-item label="别名链接:">
        <el-input v-model="product_model.alias" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item
        label="完整链接:"
        v-if="$route.query.id !== undefined && $route.query.id.length > 0"
      >
        <el-input
          class="border_none"
          :value="$common.preview_domain + product_model.alias"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item
        class="qrcode_box"
        label="二维码图片:"
        v-if="$route.query.id !== undefined && $route.query.id.length > 0"
      >
        <div id="qrCode" ref="qrCodeDiv" class="qrCode"></div>
        <el-link
          :underline="false"
          @click.prevent="downloadClick"
          type="primary"
          >下载</el-link
        >
      </el-form-item>
      <el-form-item label="产品类型:" prop="type_id">
        <el-select
          v-model="product_model.type_id"
          @change="typeChangeOnclick"
          placeholder="请选择"
          loading-text="加载中..."
        >
          <el-option
            v-for="item in product_type_list"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题:" prop="title">
        <el-input v-model="product_model.title" clearable></el-input>
      </el-form-item>
      <el-form-item label="产品背景颜色:">
        <el-color-picker
          v-model="product_model.feature_background_color"
        ></el-color-picker>
      </el-form-item>
      <el-form-item label="产品侧边颜色:">
        <el-color-picker
          v-model="product_model.feature_side_color"
        ></el-color-picker>
      </el-form-item>
      <el-form-item label="产品logo:">
        <el-input v-model="product_model.feature_logo" clearable></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              style="width: 240px; height: 240px; margin-top: 10px"
              :src="
                product_model.feature_logo + $common.K_QINIU_IMAGE_SMALL_END_200
              "
              :preview-src-list="[product_model.feature_logo]"
              lazy
              fit="contain"
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl>
              <dt class="edit-row-dt">
                <el-button
                  size="mini"
                  type="warning"
                  @click="getImageByWeb('feature_logo')"
                  >资源选择</el-button
                >
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="SEO标题:">
        <el-input
          v-model="product_model.seo_title"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="SEO描述:">
        <el-input
          v-model="product_model.seo_description"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="电脑展示图:">
        <el-input v-model="product_model.pc_banner" clearable></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              style="width: 240px; height: 240px; margin-top: 10px"
              :src="
                product_model.pc_banner + $common.K_QINIU_IMAGE_SMALL_END_200
              "
              :preview-src-list="[product_model.pc_banner]"
              lazy
              fit="contain"
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl>
              <dt class="edit-row-dt">
                <el-button
                  size="mini"
                  type="warning"
                  @click="getImageByWeb('pc_banner')"
                  >资源选择</el-button
                >
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="手机展示图:">
        <el-input v-model="product_model.wap_banner" clearable></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              style="width: 240px; height: 240px; margin-top: 10px"
              :src="
                product_model.wap_banner + $common.K_QINIU_IMAGE_SMALL_END_200
              "
              :preview-src-list="[product_model.wap_banner]"
              lazy
              fit="contain"
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl>
              <dt class="edit-row-dt">
                <el-button
                  size="mini"
                  type="warning"
                  @click="getImageByWeb('wap_banner')"
                  >资源选择</el-button
                >
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <div style="height: 4px"></div>
      </el-form-item>
      <el-form-item>
        <el-button
          style="width: 500px; height: 50px"
          type="danger"
          @click="onSubmit"
          >保存</el-button
        >
      </el-form-item>
      <el-form-item>
        <div style="height: 40px"></div>
      </el-form-item>
      <el-form-item label="详情预览：">
        <a
          :href="
            K_PREVIEW_DOMAIN +
            product_model.alias +
            '?copywriter=' +
            $common.Copywriter
          "
          target="_blank"
        >
          <el-button
            style="width: 100px; height: 50px"
            type="primary"
            :disabled="!productId"
            >预览</el-button
          >
        </a>
        <span class="warning">（仅用于预览，不能用于生成二维码）</span>
      </el-form-item>
    </el-form>
    <FileChooseDrawerModule ref="productDetailFileChooseDrawerModule" />
  </div>
</template>

<script>
import FileChooseDrawerModule from "../../../file/module/FileChooseDrawerModule";
import CopyPaste from "../../../../components/CopyPaste.vue";
import { K_PREVIEW_DOMAIN } from "../../../../../public/static/js/config";
import QRCode from "qrcodejs2";

export default {
  // 产品详情 - 基本信息编辑页面
  name: "ProductDetailInfoModule",
  components: {
    FileChooseDrawerModule,
    CopyPaste,
  },
  props: {
    productId: {
      type: String,
      default: "",
    },
    operateType: {
      type: String,
      default: "",
    },
  },
  created() {
    this.getProductTypeList();
    if (this.operate_type.length > 0) {
      this.product_operate_type = this.operate_type;
      if (this.product_id.length > 0) {
        this.product_model_id = this.product_id;
        this.getProductModel();
      }
    } else {
      this.$message.error("参数错误");
    }
  },
  data() {
    return {
      K_PREVIEW_DOMAIN,
      loading: false,
      product_operate_type: "",
      product_model_id: "",
      product_model: {
        status: 2,
        number: 0,
        category_list: [],
      },
      product_type_list: [],
      product_attribute_list: [],
      product_tag_list: [],
      rules: {
        name: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
          { min: 4, message: "长度大于 4 个字符", trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入产品标题", trigger: "blur" }],
        type_id: [
          { required: true, message: "请选择产品类型", trigger: "blur" },
        ],
      },
      year_list: [],
      operate_type: this.operateType,
      product_id: this.productId,
    };
  },
  methods: {
    async onSubmit() {
      if (!(await this.submitValidate())) return;
      if (
        this.product_model.id !== undefined &&
        this.product_model.id.length > 0
      ) {
        this.updateProduct();
      } else {
        this.addNewProduct();
      }
    },
    async submitValidate() {
      try {
        await this.$refs["mount_model"].validate();
        // 验证成功
        return true;
      } catch (err) {
        this.$message({
          message: "请检查表单",
          type: "error",
        });
        return false;
      }
    },
    addNewProduct() {
      let _me = this;
      let params = _me.product_model;
      _me.loading = true;
      _me.api
        .add_product(params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.product_operate_type = "edit";
            _me.product_model_id = res.data.id;
            _me.operate_type = "edit";
            _me.product_id = res.data.id;
            _me.product_model.id = res.data.id;
            _me.$message.success("内容保存成功");
            _me.$emit("updateFrame", _me.product_id);
            this.$router.go(-1);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    updateProduct() {
      let _me = this;
      let params = _me.product_model;
      _me.loading = true;
      _me.api
        .update_product(params)
        .then((res) => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.$message.success("内容保存成功");
            this.$router.go(-1);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    typeChangeOnclick(value) {
      // 获取产品类型
      this.product_model.type_id = value;
      // let _me = this;
      // this.loading = true;
      // this.$common
      //     .httpPost("/api/product/admin/category/find/tree/parent", {
      //       type_id: value,
      //     })
      //     .then((res) => {
      //       _me.loading = false;
      //       if (res.code == "1000000") {
      //         _me.product_model.category_list = res.data.category_list;
      //         console.log("数据:", _me.product_model.category_list);
      //       }
      //     })
      //     .catch((err) => {
      //       _me.loading = false;
      //       _me.$message({
      //         message: err || "获取数据失败",
      //         type: "error",
      //       });
      //     });
    },
    getProductModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.product_model_id,
      };
      _me.api
        .get_product_id(params)
        .then((res) => {
          _me.loading = false;
          if (res.code == "1000000") {
            _me.product_model = res.data;
            if (
              _me.product_model &&
              Object.keys(_me.product_model).length > 0
            ) {
              _me.bindQRCode();
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    getProductTypeList() {
      let _me = this;
      let key = "productTypeList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.product_type_list = val;
        return;
      }

      let params = {
        page_size: 20,
        page_number: 1,
        sort_fields: "number",
      };
      _me.$common
        .httpPost("/api/product/admin/type/find/list", params)
        .then((res) => {
          if (res.code == "1000000") {
            _me.product_type_list = res.data.array;
          }

          _me.$common.setGlobalStorage(key, _me.product_type_list);
        })
        .catch((err) => {
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    getImageByWeb(imageType) {
      let _me = this;
      this.$refs.productDetailFileChooseDrawerModule.showChooseDrawer(function (
        chooseFileList
      ) {
        console.log("接收到选择的文件:", chooseFileList);
        if (chooseFileList.length > 0) {
          switch (imageType) {
            case "pc_banner":
              _me.product_model.pc_banner = chooseFileList[0].file_url;
              break;
            case "wap_banner":
              _me.product_model.wap_banner = chooseFileList[0].file_url;
              break;
            case "star_pc_banner":
              _me.product_model.star_pc_banner = chooseFileList[0].file_url;
              break;
            case "star_wap_banner":
              _me.product_model.star_wap_banner = chooseFileList[0].file_url;
              break;
            case "star_wap_title_bg":
              _me.product_model.star_wap_title_bg = chooseFileList[0].file_url;
              break;
            case "feature_logo":
              _me.product_model.feature_logo = chooseFileList[0].file_url;
              break;
            default:
              break;
          }
        }
      });
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.product_model = model;
      this.product_model.id = this.product_id;
      var str = model.alias.split("/")[2];
      this.product_type_list.forEach((ele, i) => {
        if (ele.alias == str) {
          this.product_model.type_id = ele.id;
        }
      });
    },
    // 完整链接二维码图片
    bindQRCode: function () {
      let url = this.$common.preview_domain + this.product_model.alias;
      new QRCode(this.$refs.qrCodeDiv, {
        text: url,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
        // text: "扫描二维码，访问前台",
      });
    },
    downloadClick() {
      // 先找到canvas节点下的二维码图片
      const myCanvas = document
        .getElementById("qrCode")
        .getElementsByTagName("canvas");
      const img = document.getElementById("qrCode").getElementsByTagName("img");
      // 创建一个a节点
      const a = document.createElement("a");
      // 设置a的href属性将canvas变成png格式
      const imgURL = myCanvas[0].toDataURL("image/jpg");
      const ua = navigator.userAgent;
      if (ua.indexOf("Trident") !== -1 && ua.indexOf("Windows") !== -1) {
        // IE内核 并且  windows系统 情况下 才执行;
        var bstr = atob(imgURL.split(",")[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const blob = new Blob([u8arr]);
        window.navigator.msSaveOrOpenBlob(blob, "下载" + "." + "png");
      } else if (ua.indexOf("Firefox") > -1) {
        // 火狐兼容下载
        const blob = this.base64ToBlob(imgURL); //new Blob([content]);
        const evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        a.download = " "; //下载图片名称，如果填内容识别不到，下载为未知文件，所以我这里就不填为空
        a.href = URL.createObjectURL(blob);
        a.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
      } else {
        // 谷歌兼容下载
        img.src = myCanvas[0].toDataURL("image/jpg");
        a.href = img.src;
        // 设置下载文件的名字
        // a.download = "二维码图片";
        a.download = this.product_model.alias;
        // 点击
        a.click();

        this.$message({
          message: "二维码图片下载成功",
          type: "success",
        });
      }
    },

    // base64转blob
    base64ToBlob(code) {
      let parts = code.split(";base64,");
      let contentType = parts[0].split(":")[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], {
        type: contentType,
      });
    },
  },
};
</script>

<style scoped>
.product-info-form {
  margin: 20px 10px 10px 0;
  text-align: left;
  min-width: 720px;
  max-width: 720px;
}
.warning {
  color: red;
}

.qrCode {
  height: 250px;
  display: flex;
  margin-top: 10px;
  margin-left: 80px;
  margin-bottom: 20px;
}
</style>
<style>
.border_none input {
  border: none;
}
.qrcode_box .el-form-item__content {
  display: flex;
}
.qrcode_box .el-link {
  align-items: end !important;
  text-decoration: none !important;
}
</style>
